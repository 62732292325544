<template>
  <mobile-screen
    :header="true"
    :footer="false"
    screen-class="icon-app1 outlook-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="outlook-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <button>
              <icon icon="#cx-hea1-edit-cancel" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("outlook", "outlook", "add-resource") }}
        </div>
        <template v-slot:right>
          <div @click="applyFilters()">
            <button>
              <icon icon="#cx-hea1-edit-done" />
            </button>
          </div>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul v-if="warningNumber" class="clebex-item-section tiny-top-spacing">
      <li class="clebex-item-section-item warning-center">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div class="warning-center">
                {{ displayLabelName("outlook", "outlook", "warning-center") }}
              </div>
            </span>
            <div class="warning-count-wrapper">
              <div class="warning-count-number">{{ warningNumber }}</div>
              <icon icon="#cx-men1-warning" width="10" height="10" />
            </div>
          </div>
        </div>
      </li>
      <li
        v-for="(warningMessage, i) in warningMessages"
        :key="i"
        class="clebex-item-section-item no-separator warning-line"
      >
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                class="error"
                icon="#cx-men1-warning"
                width="20"
                height="20"
              />
            </span>
          </span>
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label p-0">
              <div>
                {{ warningMessage.message.replace(/.$/, "") }}:
                <span class="resource-name">{{
                  warningMessage.resourceName.join(", ")
                }}</span>
              </div>
            </label>
          </div>
        </div>
      </li>
      <!-- <li class="clebex-item-section-item no-separator warning-line">
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                class="warning"
                icon="#cx-men1-warning"
                width="20"
                height="20"
              />
            </span>
          </span>
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label p-0">
              <div>You have <span>3</span> unavailable attendees</div>
            </label>
          </div>
        </div>
      </li> -->
    </ul>
    <ul class="clebex-item-section tiny-top-spacing location-wrapper">
      <li class="clebex-item-section-item location-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{ displayLabelName("outlook", "outlook", "location") }}
              </div>
            </span>
          </div>
        </div>
      </li>
      <section class="levels">
        <li @click="setLocation()" class="clebex-item-section-item level">
          <button class="clebex-item-content-wrapper">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-favourite" width="20" height="20" />
              </span>
            </span>
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >Resource name</label
              >
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-checkmark" width="20" height="20" />
              </span>
            </span>
          </button>
        </li>
        <li @click="setLocation()" class="clebex-item-section-item level">
          <button class="clebex-item-content-wrapper">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-favourite" width="20" height="20" />
              </span>
            </span>
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >Resource name</label
              >
            </div>
          </button>
        </li>
        <li @click="setLocation()" class="clebex-item-section-item level">
          <button class="clebex-item-content-wrapper">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-favourite" width="20" height="20" />
              </span>
            </span>
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >Resource name</label
              >
            </div>
          </button>
        </li>
      </section>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing types-wrapper">
      <li class="clebex-item-section-item types-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{
                  displayLabelName("outlook", "add-resource", "resource-types")
                }}
              </div>
            </span>
            <div class="attendees-warning-wrapper">
              <div class="warning-count-wrapper">
                <div class="warning-count-number">
                  1
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item types">
        <div class="clebex-item-content-wrapper">
          <div class="type selected">
            <icon
              @click="setType()"
              class="error"
              icon="#cx-msg1-declined-100x100"
              width="18"
              height="18"
            />
            <div class="type-title">type 1</div>
          </div>
          <div class="type selected">
            <icon
              @click="setType()"
              class="error"
              icon="#cx-msg1-declined-100x100"
              width="18"
              height="18"
            />
            <div class="type-title">type 2</div>
          </div>
        </div>
        <div class="clebex-item-content-wrapper">
          <div @click="setType()" class="type">
            <div class="type-title">type 1</div>
          </div>
          <div @click="setType()" class="type">
            <div class="type-title">type 2</div>
          </div>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing attributes-wrapper">
      <li class="clebex-item-section-item attributes-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{
                  displayLabelName(
                    "outlook",
                    "add-resource",
                    "resource-attributes"
                  )
                }}
              </div>
            </span>
            <div class="attendees-warning-wrapper">
              <div class="warning-count-wrapper">
                <div class="warning-count-number">
                  1
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item attributes">
        <div class="clebex-item-content-wrapper">
          <div class="attribute selected">
            <icon
              @click="setAttribute()"
              class="error"
              icon="#cx-msg1-declined-100x100"
              width="18"
              height="18"
            />
            <div class="attribute-title">type 1</div>
          </div>
          <div class="attribute selected">
            <icon
              @click="setAttribute()"
              class="error"
              icon="#cx-msg1-declined-100x100"
              width="18"
              height="18"
            />
            <div class="attribute-title">type 2</div>
          </div>
        </div>
        <div class="clebex-item-content-wrapper">
          <div @click="setAttribute()" class="attribute">
            <div class="attribute-title">type 1</div>
          </div>
          <div @click="setAttribute()" class="attribute">
            <div class="attribute-title">type 2</div>
          </div>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing suggested-resources">
      <li class="clebex-item-section-item resources-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{
                  displayLabelName("outlook", "outlook", "suggested-resources")
                }}
              </div>
            </span>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item no-separator resources">
        <div class="resource-wrapper">
          <icon
            @click="handleResources(resource.email)"
            icon="#cx-msg1-declined-100x100"
            width="20"
            height="20"
          />
          <div class="resource-name">
            Room 5.06,
            <span>5th floor, clebex sky tower</span>
          </div>
        </div>
        <div class="resource-wrapper">
          <icon
            @click="handleResources(resource.email)"
            icon="#cx-msg1-declined-100x100"
            width="20"
            height="20"
          />
          <div class="resource-name">
            Room 5.06,
            <span>5th floor, clebex sky tower</span>
          </div>
        </div>
        <div class="resource-wrapper">
          <icon
            @click="handleResources(resource.email)"
            icon="#cx-msg1-declined-100x100"
            width="20"
            height="20"
          />
          <div class="resource-name">
            Room 5.06,
            <span>5th floor, clebex sky tower</span>
          </div>
        </div>
      </li>
    </ul>
    <ul
      v-if="resources && resources.length"
      class="clebex-item-section tiny-top-spacing"
    >
      <li class="clebex-item-section-item resources-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{
                  displayLabelName("outlook", "outlook", "resources-selected")
                }}
              </div>
            </span>
            <div
              v-if="!resources.find(el => el.checker)"
              class="resources-warning-wrapper"
            >
              <icon
                class="error"
                icon="#cx-men1-warning"
                width="20"
                height="20"
              />
            </div>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item no-separator resources">
        <div
          v-for="resource in resources"
          :key="resource.id"
          class="resource-wrapper"
          :class="{ disabled: !resource.checker }"
        >
          <icon
            @click="handleResources(resource.email)"
            class="error"
            icon="#cx-msg1-declined-100x100"
            width="20"
            height="20"
          />
          <div class="resource-name">
            {{ resource.name }},
            <span>{{
              resource.full_path
                .split(", ")
                .filter((el, i) => i > 0)
                .join(", ")
            }}</span>
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "AddResource",
  data() {
    return {
      reccurence: "no"
    };
  },
  computed: {
    ...mapState("outlook", ["enhancedLocation", "resources"]),
    warningNumber() {
      if (this.resources.filter(el => !el.checker).length) {
        return this.resources.filter(el => !el.checker).length;
      }
      return null;
    },
    warningMessages() {
      if (this.resources.filter(el => !el.checker).length) {
        let warnings = [];
        this.resources
          .filter(el => !el.checker)
          .forEach(element => {
            const warningIndex = warnings.findIndex(
              el => el.message === element.checker_message[0]
            );
            if (warningIndex !== -1) {
              warnings[warningIndex].resourceName = [
                ...warnings[warningIndex].resourceName,
                element.name
              ];
            } else {
              warnings.push({
                message: element.checker_message[0],
                resourceName: [element.name]
              });
            }
          });
        return warnings;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("outlook", ["setEnhancedLocation", "getResource"]),
    handleResources(email = false) {
      if (email) {
        const val = this.enhancedLocation.find(
          el => el.locationIdentifier.id === email
        ).locationIdentifier;
        if (val) {
          parent.postMessage(
            {
              call: "enhancedLocation",
              value: JSON.parse(JSON.stringify(val))
            },
            "*"
          );
        }
        return;
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
